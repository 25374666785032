.alignwide{
	margin: 32px calc(50% - 55%);
	max-width: 110%;
	width: 110%;
}
.alignfull {
	margin: 32px calc(50% - 50vw);
	max-width: 100vw;
	width: 100vw;
}

.alignfull > figcaption {
	text-align: center;
}
.alignwide > figcaption {
	text-align: center;
}

.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sticky {
	@apply border-r-2 border-riw;
}