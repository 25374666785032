#logo svg {
    width: 6em;
}

.menu-item {
    box-shadow: 0 0 #fcaa01;
    transition-property: all;
    transition-duration: 0.2s;
    font-weight: 500;
    letter-spacing: 0.05em;
    font-size:12px;
    line-height: 1em;
    @apply px-1 sm:px-3 py-2
}
.sub-menu {
    @apply pt-2 
}
.sub-menu .menu-item {
    @apply py-2 sm:ml-[-0.45rem]  
}
.menu-item:hover {
    box-shadow: -0.4em 0 #fcaa01;
}

#navIcons {
    display: flex;
    gap: 0.3em;
    @apply pt-2
}
#navIcons li {
    position: relative;
    transition: all 0.2s;
}
#navIcons li:hover {
    transform: scale(1.2);
}
#navIcons .iconCounter {
    @apply absolute text-xs rounded-full bg-riw text-white text-center px-1 -bottom-1 -right-1;
}