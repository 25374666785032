h1, h2, h3 {
    @apply my-2 font-medium tracking-wide break-words
}
h1 {
    @apply text-4xl
}
h2 {
    @apply text-2xl
}
h3 {
    @apply text-xl;
}
.contact_stripe a:hover {
    text-decoration: underline;
}
p a:hover {
    text-decoration: underline;
}

ol {
    list-style: decimal;
    list-style-position: outside;
    padding-left: 1.2em;
}
ol li ol {
    list-style: lower-latin;
    list-style-position: outside;
    padding-left: 1.2em;
}
ol li ul {
    list-style: disc;
    list-style-position: outside;
    padding-left: 1.2em;
}
