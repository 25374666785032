@tailwind base;
@tailwind components;

@import './components/wp';
@import './components/html';
@import './components/header';

/* Do posortowania start */


.category-thumbnail {
    overflow: hidden;
    position: relative;
}
.category-thumbnail img {
    width: 100%;
    transition: 0.2s all;
    margin: 0 !important;
}

ul.products li:hover .category-thumbnail img {
    transform:scale(1.1);
}
ul.products li.product-category:hover h2 {
    @apply text-riw;
}
ul.products.columns-3 {
    @apply grid grid-cols-2 sm:grid-cols-3;
    grid-gap: 10px;
}
ul.products.columns-4 {
    @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4 md:gap-6;
}
ul.products li {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
ul.products li.product h2 {
    font-size:0.8em;
    font-weight:500;
    text-align: left;
    width: 100%;
    padding:0.3em;
    transition: all 0.2s;
    line-height: normal;
    letter-spacing: 0.05em;
}
h2.woocommerce-loop-category__title {
    font-size: 1em;
}
.product .price {
    display: block;
    padding: 0.2em;
    @apply font-light text-riw-dark
}
.product .price del {
    @apply text-gray-400 font-light;
}
.product .price ins {
    text-decoration: none;
    @apply text-riw-dark font-medium;
}

.product-price .price {
    display: block;
    padding: 0.2em;
    @apply font-light
}
.product-price .price del {
    @apply text-gray-400 font-light;
}
.product-price .price ins {
    text-decoration: none;
    @apply text-riw-dark font-medium;
}

.product_buttons {
    display:flex;
    justify-content: center;
    gap:0.5em;
    padding-right:0.5rem;
}
.product_buttons > * {
    min-width: 24px;
    @apply my-2 text-center;
    transition: all 0.2s;
}
.product_buttons > *:hover {
    transform:scale(1.3);
}

.yith-wcwl-add-button img:hover {
    transition: all 0.2s;
}
.yith-wcwl-add-button img:hover {
    transform:scale(1.3);
}

/* Do posortowania koniec */

@tailwind utilities;

.hide {
    display:none;
}

#yith-wcwl-popup-message {
    font-weight: 200;
    line-height: 50px;
    text-align: center;
    background: white;
    box-shadow: 0 10px 10px rgba(0,0,0,.3);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 7px 40px;
    z-index: 10000; /* Thanks WP -.- */
}


.yith-wcwl-add-to-wishlist a.add_to_wishlist>img, .yith-wcwl-add-to-wishlist a.delete_item>img, .yith-wcwl-add-to-wishlist a.move_to_another_wishlist>img {
    margin-right: 0;
}

.wishlist_exists {
    opacity: 0;
    transition: all 0.5s;
}
.fade_in {
    animation: fadeIn 500ms 1 forwards;
}
@keyframes fadeIn {
    0% {
        opacity:0;
        }
    100% {
        opacity: 1;
    }
}

.new-product-badge {
    position: absolute;
    top:1em;
    left:1em;
    padding: 0.2em 0.5em;
    letter-spacing: 0.05em;
    display:block;
    @apply bg-green-600 text-white rounded-md text-xs
}
.sale-badge {
    position: absolute;
    top:1em;
    right:1em;
    padding: 0.2em 0.5em;
    letter-spacing: 0.05em;
    display:block;
    z-index:3;
    @apply bg-riw text-white rounded-md text-xs
}
.screen-reader-text {
    @apply sr-only;
}
#navSearch {
    cursor:pointer;
}

/* aside section h5 {
    @apply mb-2
} */
aside section ul li {
    @apply hover:text-riw transition-all duration-200 border-b-2 pb-1 mb-1 hover:pl-1
}
.page-numbers {
    @apply flex justify-center gap-1;
}
.page-numbers li {
    @apply text-xs border-gray-200 border leading-9 w-[2.25rem];
}
.page-numbers li .current {
    @apply bg-riw leading-9 w-[2.25rem];
}
.page-numbers li a {
    @apply leading-9 hover:bg-riw-light transition-all duration-200;
}
.nav-links {
    @apply flex justify-center gap-1 mb-2;
}
.nav-links .page-numbers {
    @apply text-xs border-gray-200 border leading-9 w-[2.25rem];
}
.nav-links a {
    @apply leading-9 hover:bg-riw-light transition-all duration-200;
}
.nav-links .current {
    @apply bg-riw;
}


.wp-block-separator.is-style-default {
    border: 1px solid #eee;
    width: 20%;
    margin: 1em auto 1.5em auto;
}

.input-text.qty {
    text-align: center;
    display: block;
    padding: 0.18em 0.5em;
    margin-right: 0.5em;
    max-width:70px;
    @apply font-extralight border
}
p, td, address, time, li {
    font-style: normal;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 1.5rem;
  @apply text-gray-600   
}
td, th {
    vertical-align: top;
    padding: 0.5em;
}
th {
    @apply tracking-wider font-medium
}
table {
    margin: 1em 0;
    width: 100%;
}
.wc-tabs {
    @apply flex bg-white
}
.wc-tabs li{
    padding:1em;
}
.wc-tabs li.active{
    box-shadow: 0 4px rgb(250,178,0);
    color: rgb(250,178,0)
}

.sklep-widget-content li {
    font-weight: 200;
    @apply text-gray-600 text-xs py-3  
  }
.wp-block-categories-list li {
    font-weight: 200;
    @apply text-gray-600 text-xs py-3  
  }
.orderby, .woocommerce-result-count {
    font-weight: 200;
    @apply text-gray-600 text-xs   
}
.orderby {
    padding: 0.2em;
    border: solid 1px #ddd;
}

.woocommerce-privacy-policy-text {
 @apply text-xs mt-3
}

.woocommerce-MyAccount-navigation .is-active {
    @apply bg-gray-300
}

table.cart td {
    vertical-align: middle;
    @apply sm:py-4;
}
table.cart td img{
    margin:auto;
}
table.cart td input{
    margin:auto !important;
}

.required {
    color: red;
    font-weight: 600;
}

#tab-description tr {
    @apply flex flex-col sm:table-row-group
}
.product_buttons svg {
    transition: all;
    transition-duration:  200ms;
}
.product_buttons .added svg {
    fill: black;
}

#addedToCartNotice {
    position:fixed;
    top: 50%;
    left: 50%;
    color:white;
    transform: translate (50% 50%);
    z-index: 200;
    padding:2em;
    background-color: blue;
}
.loading {
    pointer-events: none;
}
.product_buttons .loading svg {
    fill: #fab200;
    animation: cartLoading 200ms infinite alternate;
}
  
  @keyframes cartLoading {
    from {
    transform: rotate(-30deg);
    }
  
    to {
    transform: rotate(30deg);
    }
  }
  
.added_to_cart.wc-forward {
    display: none;
}
.woocommerce-message {
@apply bg-gray-100 font-light border-t-2 border-green-600 p-4 text-sm
}

#logo {
    transition: all 200ms;
}
#logo:hover {
    transform: scale(1.1);
}

/* .wp-block-columns {
    @apply flex flex-col sm:flex-row gap-5;
}

.wp-block-column ul li {
    list-style: disc;
    list-style-position: outside;
    padding: 0;} 
.has-text-align-center {
    text-align: center;
}
*/

.has-riw-color { color: #fab200; }
.has-riw-background-color { background-color: #fab200; }
.has-black-color { color: rgb(0,0,0) }
.has-black-color { color: rgb(0,0,0) }
.has-graphite-color { color: #525252 }
.has-graphite-color { color: #525252 }
.has-white-color { color: rgb(255,255,255) }
.has-white-color { color: rgb(255,255,255) }
.has-red-color { color: #f56565; }
.has-red-background-color { background-color: #f56565; }
.has-green-color { color: #38a169 }
.has-green-color { color: #38a169 }
.has-blue-color { color: #4299e1 }
.has-blue-color { color: #4299e1 }


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;
}

#tab-description p {
    margin-bottom: 1em;
}

#addToCartMessage {
    font-weight: 200;
    line-height: 50px;
    text-align: center;
    background: white;
    box-shadow: 0 10px 10px rgba(0,0,0,.3);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 7px 40px;
    z-index: 10000; /* Thanks WP -.- */
}

.addToCartMessage {
    animation: addToCartMessage 2000ms 1;
}
  
  @keyframes addToCartMessage {
    0% {
        opacity:0;
        }
    20% {
        opacity: 1;
        }
    80% {
        opacity: 1;
        }
    100% {
        opacity: 0;
    }
    
}
.block-ul {
    list-style: disc;
    list-style-position: outside;
    list-style-position: outside;
    padding-left: 1.2em;
}
.wc_payment_methods li label img
{
    display: inline;
}

.form-button {
    @apply text-center font-light text-gray-600 tracking-wide border-2 border-riw bg-white hover:bg-riw transition duration-200 py-2 px-4;
}

.out-of-stock {
    font-weight: bold;
    background-color: #f56565;
    color: white;
    padding: 0.2em 0.4em;
    margin-right:0.5em;
}
.archive .outofstock .category-thumbnail img {
    opacity: 0.3;
}
.archive .outofstock .category-thumbnail::after {
    text-align:center;
    content: "Brak w magazynie";
    background-color: white;
    color: #525252;
    border: 2px #525252 solid;
    padding: 0.4em 0.8em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 6px rgba(0,0,0,0.4);
    @apply text-sm
}
